import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../../components/filtercard.js';

function MeltFilters() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'PP Melt Spray Filter',
      src:'/images/filters/PPMeltSprayFilter1.png',
      desc:'Polypropylene is used as raw material, which is heated and melted by spraying to form a deep filter element with fixed pore size gradient is produced in one go. It has a sparse outer and dense inner structure, which can evenly intercept pollutants of different sizes. The inner and outer layers of the filter core are often used for pre-filtration of liquids.',
      features:["Acid and Alkali resistant","Strong pollution holding capacity","Multi-layer filtration","No Adhesive"],
      uses:["Electronics Industry","Pure Water Filtration","Pre-filtration for various types of precision filtration","Chemical Filtration","Filtration in the beverates manufacturing process","Pre-filtration before RO and UF, industrial use","Various water treatment"],
      material:'Polypropylene',
      innerDiameter:'28mm',
      outerDiameter:["63mm","115mm"],
      length:'5 to 40 inch',
      aperture:'0.5 to 50µm',
      endCap:["Double Opening","2-222 tail/rocket tail","2-226 horizontal/rocket"],
      minTemp:'60°C',
      maxPressure:["0.3Mpa at 20°C","0.2Mpa at 40°C","1.0Mpa at 60°C"],
    },
    {
      title:'PP Industrial Melt Blown Filter',
      src:'/images/filters/PPIndustrialMeltBlownFilter2.png',
      desc:'The filter element adopts a three-layer structure design. After the PP raw material is melted, it is formed by high temperature and high pressure wire spraying. Since the filter element has a sparse outer layer and dense inner layer structure, pollutants of different sizes can be evenly intercepted in the inner and outer layers of the filter element. It has an extremely good deep filtration effect and pollutant accumulation, and can maintain a stable flow rate, pressure difference and service life. The fiber structure is stable, which can ensure the filtration quality and is suitable for precision industrial processes.',
      features:["Made of PP ultra-fine fiber","Filtration efficiency can reach up to 90%","Three-layer filter structure has high impurity tolerances","Long service life","Specially treated centerand surface","No fibre loss","Longer lifespan as compared with other brands up to 30%","Made of 100% pure PP","Excellent chemical compatibility","No other subtances will be extraced/filtered during process"],
      uses:["PCB Industry","Electronics Industry","Beverage Industry","Recycled water filtration","Chemical Process","Petrochemical Process"],
      material:'Polypropylene',
      innerDiameter:'28mm',
      outerDiameter:["63mm","115mm"],
      length:'5 to 40 inch',
      aperture:'0.5 to 50µm',
      endCap:["Double Opening","2-222 tail/rocket end","2-226 horizontal/rocket end"],
      minTemp:'60°C',
      maxPressure:["0.3Mpa at 20°C","0.2Mpa at 40°C","1.0Mpa at 70°C"],
    },
    {
      title:'Absolute Filter',
      src:'/images/filters/AbsoluteFilter3.png',
      desc:'The filter fibers are tightly interwoven from the outside to the inside and are not affected by external pumps. Excessive pressure or high pressure difference in the filter core may cause pollutants to be released and the filtration efficiency up to 99%, due to the extremely significant filtering effect. Suitable for industries with strict process requirements.',
      features:["Japan latest fiber technology","High filtration efficiency 99% to achieve consistent quality","Progressive absolute aperture structure for better filtration","Hgih capacity/Long Service Life","Stable flow rate","Made of polyoefin (PP & PE) material","Strong and durable","Strong acid and alkaline","High hardness & High pressure resistance","Maximum pressure difference can reach up to 0.55Mpa"],
      uses:["Paint/Coating Industry","Ceramic Industry","Optical Film Industry","Acrylic Industry","Textile Industry","Circuit Board Copper Laminate Industry","Chemical Filtration"],
      material:'Polypropylene',
      innerDiameter:'30mm',
      outerDiameter:["62mm","68mm"],
      length:'10/20 inch',
      aperture:'1.0 to 150µm',
      endCap:["Double Opening","2-222 tail/rocket end","2-226 horizontal/rocket end"],
      minTemp:'80°C',
      maxPressure:["0.55Mpa at 20°C","0.3Mpa at 60°C","0.2Mpa at 80°C"],
    },
    {
      title:'Wrap Filter',
      src:'/images/filters/WrapFilter4.png',
      desc:'The spun fiber yarn is tightly wound on the porous skeleton according to a specific process, and the winding density and filter hole shape of the filter layer are controlled to produce different filter pore sizes. The filter core has a honeycomb structure that is sparse outside and dense inside, and has good deep filtering properties. Filtering effect, can effectively remove suspended matter, rust, particles in the liquid particles and other impurities. The filter core can be made of a variety of materials to adapt to various liquid filtration needs.',
      features:["Acid and Alkali resistant","Large capacity","Large flow rate","Good chemical compatibility","Efficient filtration","No adhesive"],
      uses:["Water (Industrial & consumer) & Food & Beverage Industry using as pre-filter","Developer, Etch and Plating Solution","Organic solvent pre-treatment","Cooling loop water, Boiler water & Ultrapure water using as filters","Acid based liquid, resin, adhesive, paint","Pre-filter for ink, photographic processing fluid"],
      material:["Fiber Polypropylene","Degreased cotton thread, glass fiber","Frame: Polypropylene, stainless steel"],
      innerDiameter:["28mm","30mm"],
      outerDiameter:["63mm","115mm"],
      length:'10/40 inch',
      aperture:'1.0 to 100µm',
      endCap:["Double Opening","2-222 tail/rocket end","2-226 horizontal/rocket end"],
      minTemp:["60°C (PP)","120°C (degreased cotton thread)","200°C (fiberglass, stainless steel)"],
      maxPressure:["0.2Mpa at 20°C"],
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Melt Filters</h3>
                <br/>
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/><br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4 id='section1'>Product Specifications</h4>
            <div className='table-responsive'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Filter</th>
                    <th scope="col">Material</th>
                    <th scope="col">Inner Diameter</th>
                    <th scope="col">Outer Diameter</th>
                    <th scope="col">Length</th>
                    <th scope="col">Aperture</th>
                    <th scope="col">End Cap</th>
                    <th scope="col">Minimum Operating Temp</th>
                    <th scope="col">Maximum Opertating Pressure Differences</th>
                  </tr>
                </thead>
                <tbody>
                  {FilterList.map((product, index) => (
                    <tr key={index}>
                      <td>{product.title}</td>
                      {/* <td>{product.material}</td> */}
                      <td>
                        {Array.isArray(product.material) ? (
                          product.material.map((material, i) => (
                            <span key={i}>{material}<br /></span>
                          ))
                        ) : (
                          product.material
                        )}
                      </td>
                      {/* <td>{product.innerDiameter}</td> */}
                      <td>
                        {Array.isArray(product.innerDiameter) ? (
                          product.innerDiameter.map((innerDiameter, i) => (
                            <span key={i}>{innerDiameter}<br /></span>
                          ))
                        ) : (
                          product.innerDiameter
                        )}
                      </td>
                      {/* <td>{product.outerDiameter}</td> */}
                      <td>
                        {Array.isArray(product.outerDiameter) ? (
                          product.outerDiameter.map((diameter, i) => (
                            <span key={i}>{diameter}<br /></span>
                          ))
                        ) : (
                          product.outerDiameter
                        )}
                      </td>
                      <td>{product.length}</td>
                      <td>{product.aperture}</td>
                      {/* <td>{product.endCap}</td> */}
                      <td>
                        {Array.isArray(product.endCap) ? (
                          product.endCap.map((endCap, i) => (
                            <span key={i}>{endCap}<br /></span>
                          ))
                        ) : (
                          product.endCap
                        )}
                      </td>
                      {/* <td>{product.minTemp}</td> */}
                      <td>
                        {Array.isArray(product.minTemp) ? (
                          product.minTemp.map((minTemp, i) => (
                            <span key={i}>{minTemp}<br /></span>
                          ))
                        ) : (
                          product.minTemp
                        )}
                      </td>
                      {/* <td>{product.maxPressure}</td> */}
                      <td>
                        {Array.isArray(product.maxPressure) ? (
                          product.maxPressure.map((maxPressure, i) => (
                            <span key={i}>{maxPressure}<br /></span>
                          ))
                        ) : (
                          product.maxPressure
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default MeltFilters