import './handlereclaimscraps.css'
import ListItemWithImage from '../../components/imagepopup.js'
import '../../App.js'


function HandleReclaimScraps() {
  const images=[
    {src:'/images/HandlingBefore1.jpg', buttonLabel:'Photos of parts received', popupTitle:'Photos of parts received (Sample)'}
    ,{src:'/images/HandlingAfter.png', buttonLabel:'Photos of part after crushing process', popupTitle:'Photos of part after crushing process (Sample)'}
  ]
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-6'>
            <div className='text-start'>
              <h3>Reclaim and recovery of Precious Metals</h3>
              <br/>
              <h5 className='fw-normal'>Ability to reclaim precious metals such as gold(Au), platinum(Pt), silver(Ag) and ruthenium(Ru)</h5>
              <h5 className='fw-normal'>Reclaim of precious metals from various processes such as sputter shields, spent targets, chemical solutions and filters.</h5>
              <h5 className='fw-normal'>Reclaim report shall be provided</h5>
              <h5 className='fw-normal'>Reclaim amount value to park back to customers as gold bar, gold pellets or electronic telegraph transfer to customer's bank account</h5>
              <br/>
            </div>
          </div>
          <div className='col-12 col-md-6'>
              <img src='/images/ReclaimChart.png' className='ReclaimChartImg' alt='ReclaimChart' />
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-6'>
            <div className='text-start'>
              <h3>Handling of material scraps</h3>
              <br/>
              <h5 className='fw-normal'>Ability to perform scrapping of highly confidential parts with proprietary design/patent</h5>
              <h5 className='fw-normal'>Ensure proper accountability with 100% physical count. For broken parts, it shall be determined by weight</h5>
              <h5 className='fw-normal'>Parts scrapping services are provided with the following process flow:</h5>
              <ul className="list-group list-group-numbered">
                {images.map((item, index) => (
                  <ListItemWithImage 
                    key={index} 
                    imageSrc={item.src} 
                    buttonLabel={item.buttonLabel} 
                    popupTitle={item.popupTitle} 
                  />
                ))}
                <li class="list-group-item">Certificate of Disposal (COD) shall be provided</li>
              </ul>
              <br/>
            </div>
          </div>
          <div className='col-12 col-md-6'>
              <img src='/images/HandlingScrap.jpg' className='ReclaimChartImg' alt='ReclaimChart' />
          </div>
        </div>
    </div>
    <br/>
    <br/>
    </>
  )
}

export default HandleReclaimScraps