import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../..//components/filtercard.js';
import TablePopup from '../../../components/tablepopup.js';

function FilterBag() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'Non-woven Filter Bag',
      src:'/images/filters/NonWovenFilterBag1.png',
      desc:'The surface is specially treated with scorching hair to effectively prevent fiber. The filter removes pollutants. It has a uniform three-layer structure. The filter layer has a very high capture rate for solid and colloidal particles. Capturing ability, high strength long fibers make the filter bag having stable filtration efficiency and longer service life.',
    },
    {
      title:'Mesh Filter Bags',
      src:'/images/filters/MeshFilterBag2.png',
      desc:'Strong pollution absorption capacity. Large flow rate High pressure resistance Easy to operate, high interception rate Wide application range. Stable filtration efficiency. The bag can be taken out and clean repeatedly to extend service life. Suitable for filtering liquids with high impurity content.',
    },
    {
      title:'Stainless Steel Filter Bag',
      src:'/images/filters/StainlessSteelFilterBag3.png',
      desc:'The non-deformable stainless steel spun wire SUS304 or SUS316 is used to weave the mesh according to the specified specifications. After heat setting, it becomes a single-filament mesh with high strength and will not be deformed due to changes in pressure. It forms a smooth surface, is easy to clean, and can be used repeatedly.',
    },
    {
        title:'PTFE Filter Bag',
        src:'/images/filters/PTFEFilterBag3.png',
        desc:'The continuous working temperature is above 250 degrees, the instantaneous temperature is 280 degrees, it is completely resistant to acid, alkali and oxidation, almost non-flammable, the only fiber that will not age, it can meet the most severe environment, and has an extremely long service life. ',
    },
    {
        title:'Oil Filter Bag',
        src:'/images/filters/PTFEFilterBag3.png',
        desc:'Highly efficient oil absorption, can quickly absorb oil that is higher than its own weight. Various types of oils, multi-layer composite deep filtering structure, double-layer effect, absorb Oil and impurities interception, wide range of filter material chemical compatibility, slag tolerance Large quantity and long service life.',
    },
    {
        title:'Absolute Pore Size Filter Bags',
        src:'/images/filters/AbsolutePoreSizeFilterBags5.png',
        desc:'For applications requiring absolute pore size filtration, absolute filter bags are a high performance A new option for expression, absolute gradient deep filtering provides the filter bag with extremely high filtration efficiency (above 99.98%) and a long service life. Compared with other filtration technologies, the absolute filter bag has low operating costs and easy to change bags.',
    },
    {
        title:'Dust Filter Bags',
        src:'/images/filters/DustFilterBags6.png',
        desc:'The dust filter bag is the heart of the bag dust collector operation process.The cylindrical pulse filter bag is hung vertically in the dust collector, containing dust. Gas enters the dust collector from the air inlet and passes through the guide plate of the ash hopper. The large dust particles in the gas are separated by inertial force. The dust-laden gas enters the filterbox. In the filter bag area, most of the dust is captured on the outer surface of the filter bag. The clean gas passes through the filter material and enters the filter bag. The gas enters the upper box through the filter bag opening and is then discharged from the air outlet. The cage frame inside the filter bag is used to support the filter bag and prevent the filter bag from collapsing. It also helps in the removal and redistribution of dust cake.'
    },
    {
        title:'PL Single Bag Filter',
        src:'/images/filters/PLSingleBagFilter1.png',
        uses:["Beverage Industry","RO Pre-filter","PCW Filter","Chemical Process","Recycle Water Filtration","Electro-deposition Coating Filter"],
        data:[
            {
                popupTitle:'Pl Single Bag Filter Specifications',
                Material:'SUS304/SUS316/SUS316L',
                InletOutlet:'1/2 inch to 3" thread, Flange, Sanitary grade connector',
                DischargePort:'3/4 inch PT-M',
                ExhaustPort:'1.4 inch PT-F',
                OringMaterial:'EPDM, optional Viton, Teflon Wrapped Viton',
                DesignPressure:'15kg/cm² (225psi)',
                OperatingPressure:'10kg/cm² (150psi)',
                SurfaceTreatment:'External electrolysis, Internal Pickling, X-ray and EP electrolysis are optimal'
            },
        ],
    },
    {
        title:'Acrylic Dust Filter Bag',
        desc:'Acrylic needle-punched felt, chemical name is polyacrylonitrile. It is used as raw material to make felt by needle-punching, and after special water-resistant treatment, hydrolysis-resistant needlepunched filter felt in acrylic is obtained. The air permeability is 14M3/M2·min@200Pa with operating temperature at 140℃. Normal operating temperature is 120℃. Needle-punched felt uses acrylic machine woven fabric as the base fabric to enhance the longitudinal and transverse strength. It has excellent chemical resistance and hydrolysis resistance, and is widely used in dust collection of flue gas such as asphalt, dryer, coal mill, and power plant sintering machine.'
    },
    {
        title:'PPS Dust Filter Bag',
        desc:'PPS needle-punched felt is a kind of filtration material made of PPS fiber (polyphenylene sulfide) through three-dimensional needle punching. It can be used for a long time at a temperature of about 160°C. PPS fiber, also known as polyphenylene sulfide fiber, has good chemical stability and strength integrity due to its special molecular structure. I It is particularly effective in a flue gas environment with high sulfur content. It is suitable for coal-fired boilers, electricity, garbage incineration and other highly acidic flue gas filtration environments.'
    },
    {
        title:'Aramid Filter Bag',
        desc:'Aramid (common name: aromatic polyamide fiber) has a long-term temperature resistance of 204℃, an instantaneous temperature resistance of 240℃,strong alkali resistance, and medium acid resistance. It is a good material for handling high-temperature gases of 180~220℃. Aramid needle-punched filter media is widely used in steel, cement, electricity, chemical and other industries, and is an ideal flue gas dust removal filter material.'
    },
    {
        title:'P84 High Temperature Resistant Dust Filter Bag',
        desc:'P84 needle-punched felt is made of P84 fiber through three-dimensional needle punching. P84 fiber (polyimide fiber) has excellent high temperature resistance, up to 260°C, and the cross section of P84 fiber is irregular leaf-shaped, which can greatly improve the filtering effect. The fiber itself also has certain resistance to hydrolysis and oxidation. This product has good temperature resistance and good filtering effect, and is widely used in complex conditions such as concrete mixing, cement, garbage incineration, coal-fired boilers, etc.'
    },
    {
        title:'PTFE Dust Filter Bag',
        desc:'PTFE needle-punched felt is a kind of filter made of PTFE (polytetrafluoroethylene fiber) through three-dimensional needle punching. PTFE fiber is a macromolecular linear structure, so it has strong heat resistance, wear resistance, corrosion resistance, and chemical stability. It is widely used in various harsh flue gas filtration environments such as steel, electricity, and waste incineration.'
    },
    {
        title:'Glass Fiber Filter Bag',
        desc:'Glass fiber is a high-temperature resistant filter material with reasonable structure and good performance. It not only has the advantages of glass fiber fabric, such as high temperature resistance, corrosion resistance, stable size, extremely small elongation and shrinkage, and high strength, but also the felt layer fiber is single-fiber, three-dimensional microporous structure, high porosity, and small resistance to gas filtration. It is a high-speed and efficient high-temperature filter material. Compared with other hightemperature resistant chemical fiber felts, it has special advantages such as low cost, low running resistance, high filtration pore size, and higher temperature resistance. It is suitable for high- temperature flue gas filtration in industries such as steel, metallurgy, carbon black, power generation, cement, and chemicals. '
    },
    {
        title:'Film  Filter Bag',
        desc:"We can provide corresponding coated filter bags for all the above-mentioned filter bags. PTFE microporous membrane can effectively filter dust with a particle size of 2.5um. It is the first choice for solving PM2.5 and an important way to ensure that the emission concentration is below 10mg/Nm3 in various situations. Our company cooperates with world-renowned PTFE microporous membrane manufacturers and uses high-transmittance and low-resistance three-microporous membrane to develop and produce coated filter bags of various materials to meet customers' ultra-clean emissions."
    },
    {
        title:'Anti-Static Dust Filter Bag',
        desc:'The filter cloths used in anti-static dust filter bags are all made of anti-static polyester needle-punched felt, which has excellent anti-static performance. They are used in industries such as flour dust, chemical dust, coal dust, etc., which may explode if electrostatic discharge occurs. They are the most ideal choice for explosion-proof dust collection. When the concentration of industrial dust reaches a certain level (i.e., the explosion limit), it is very easy to cause explosion and fire if it encounters factors such as electrostatic discharge sparks or external ignition. For example, flour dust, chemical dust, coal dust, etc. may explode if they encounter electrostatic discharge. In the field of bag dust removal, if these dusts need to be collected by filter bags, the filter material used to make needle- punched felt anti-static dust collectors is required to have anti-static properties, that is, permanent anti-static needle-puncture filter bags.'
    },
  ]
  const filterTable=[
    {
        title:'Non-woven Filter Bag',
        material:'Polypropylene (PP)',
        ring:'Stainless Steel, Galvanized, Nylon, PP, PE',
        type:'Sewing/Hot melt welding',
        aperture:'0.5 to 300µm',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","#5-Dia150 x L520mm","Y series-Dia202 x L330mm","Other special sizes available"],
        tempRes:'90°C',
        acidRes:'Good',
        alkaliRes:'Good'
    },
    {
        title:'Non-woven Filter Bag',
        material:'Polyester',
        ring:'Stainless Steel, Galvanized, Nylon, PP, PE',
        type:'Sewing/Hot melt welding',
        aperture:'0.5 to 300µm',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","#5-Dia150 x L520mm","Y series-Dia202 x L330mm","Other special sizes available"],
        tempRes:'135°C',
        acidRes:'Standard',
        alkaliRes:'Standard'
    },
    {
        title:'Mesh Filter Bag',
        material:'Nylon',
        ring:'Stainless Steel, Galvanized, Nylon, PP, PE',
        type:'Sewing/Hot melt welding',
        aperture:'10 to 800mesh',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","#5-Dia150 x L520mm","Y series-Dia202 x L330mm","Other special sizes available"],
        tempRes:'160°C',
        acidRes:'Not Good',
        alkaliRes:'Good'
    },
    {
        title:'Mesh Filter Bag',
        material:'Polyester(PEMO)',
        ring:'Stainless Steel, Galvanized, Nylon, PP, PE',
        type:'Sewing/Hot melt welding',
        aperture:'20 to 400 mesh',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","#5-Dia150 x L520mm","Y series-Dia202 x L330mm","Other special sizes available"],
        tempRes:'135°C',
        acidRes:'Standard',
        alkaliRes:'Standard'
    },
    {
        title:'Stainless Steel Filter Bag',
        material:'Stainless Steel',
        ring:'Stainless Steel, Galvanized',
        type:'',
        aperture:'60 to 500µm',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","Other special sizes available"],
        tempRes:'',
        acidRes:'',
        alkaliRes:''
    },
    {
        title:'PTFE Filter Bag',
        material:'Polytetrafkuorethlene (PTFE)',
        ring:'Stainless Steel, Galvanized',
        type:'',
        aperture:'60 to 500µm',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","#5-Dia150 x L520mm","Y series-Dia202 x L330mm","Other special sizes available"],
        tempRes:'',
        acidRes:'',
        alkaliRes:''
    },
    {
        title:'Oil Filter Bag',
        material:'Composite',
        ring:'Stainless Steel, PP',
        type:'',
        aperture:'',
        size:["#1-Dia180 x L420mm","#2-Dia180 x L810mm","#3-Dia105 x L230mm","#4-Dia105 x L380mm","#5-Dia150 x L520mm","Other special sizes available"],
        tempRes:'',
        acidRes:'',
        alkaliRes:''
    },
  ]
  const materialList = [
    {
        MaterialProperties:'Operating Temp °C (Dry)',
        PP:'90',
        Polyester:'135',
        Acrylic:'135',
        PPS:'160',
        Aramid:'204',
        P84:'240',
        PTFE:'250',
        GlassFiber:'260',
    },
    {
        MaterialProperties:'Operating Temp °C (Hot & Humid)',
        PP:'94',
        Polyester:'130',
        Acrylic:'125',
        PPS:'160',
        Aramid:'177',  
        P84:'195',
        PTFE:'250',
        GlassFiber:'260',
    },
    {
        MaterialProperties:'Max operation temperature (°C)',
        PP:'107',
        Polyester:'150',
        Acrylic:'140',
        PPS:'190',
        Aramid:'240',
        P84:'260',
        PTFE:'280',
        GlassFiber:'290',
    },
    {
        MaterialProperties:'Density (g/cm3)',
        PP:'0.9',
        Polyester:'1.38',
        Acrylic:'1.17',
        PPS:'1.38',
        Aramid:'1.38',
        P84:'1.41',
        PTFE:'2.30',
        GlassFiber:'2.54',
    },
    {
        MaterialProperties:'Relative Humidity (%)',
        PP:'0.1%',
        Polyester:'0.4%',
        Acrylic:'1.0%',
        PPS:'0.6%',
        Aramid:'4.5%',
        P84:'3.0%',
        PTFE:'0.0%',
        GlassFiber:'0.0%',
    },
    {
        MaterialProperties:'Flammable',
        PP:'Yes',
        Polyester:'Yes',
        Acrylic:'Yes',
        PPS:'No',
        Aramid:'No',
        P84:'No',
        PTFE:'No',
        GlassFiber:'No',
    },
    {
        MaterialProperties:'Biological Resistance (Bacteria / Mold)',
        PP:'V.Good',
        Polyester:'No',
        Acrylic:'Yes',
        PPS:'No',
        Aramid:'No',
        P84:'No',
        PTFE:'No',
        GlassFiber:'No',
    },
    {
        MaterialProperties:'Alkali Resistance',
        PP:'V.Good',
        Polyester:'Std',
        Acrylic:'Std',
        PPS:'V.Good',
        Aramid:'Good',
        P84:'Std',
        PTFE:'V.Good',
        GlassFiber:'Std',
    },
    {
        MaterialProperties:'Mineral Acid Resistance',
        PP:'V.Good',
        Polyester:'Std',
        Acrylic:'V.Good',
        PPS:'V.Good',
        Aramid:'Std',
        P84:'V.Good',
        PTFE:'V.Good',
        GlassFiber:'V.Good',
    },
    {
        MaterialProperties:'Organic Acid Resistance ',
        PP:'V.Good',
        Polyester:'Std',
        Acrylic:'V.Good',
        PPS:'V.Good',
        Aramid:'Std',
        P84:'V.Good',
        PTFE:'V.Good',
        GlassFiber:'V.Good',
    },
    {
        MaterialProperties:'Oxidation Stability',
        PP:'Good',
        Polyester:'Good',
        Acrylic:'Good',
        PPS:'V.Good',
        Aramid:'Poor',
        P84:'V.Good',
        PTFE:'V.Good',
        GlassFiber:'V.Good',
    },
    {
        MaterialProperties:'Organic Solvent Resistance',
        PP:'V.Good',
        Polyester:'Good',
        Acrylic:'V.Good',
        PPS:'V.Good',
        Aramid:'V.Good',
        P84:'V.Good',
        PTFE:'V.Good',
        GlassFiber:'V.Good',
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Filter Bags</h3>
                <br/>
                <h5 className='fw-normal'><span className='fw-bold'>Features:</span> Ultra high filtration efficiency up to 99.998%. 100% welded structure to prevent liquid leakage from pinholes. Unique designed ring with automatic response to pressure seal ring when pressure increases. The collar sealing ring has the same characteristics. Improve from time to time to ensure nothing is missed. Characteristics of long lasting effect and absolute aperture. Flow direction is more precise than previous layers to expel impurities. Slurry can be removed in stages without any layer becoming clogged prematurely.</h5>
                <h5 className='fw-normal'><span className='fw-bold'>Applications:</span> Food and Beverage Industry, Pharmaceutical Industry, Microelectronics Industry, Chemical Industry, Ink & Water Treatment Industry</h5>
                <br/>
                {/* <h5>View specifications below</h5> */}
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
              data={item.data}
            />
          </div>
        ))}
      </div>
      <br/>
      <h4 id='section1'>Comparison of different filter bag materials properties</h4>
      <TablePopup 
        buttonLabel="View Comparison Table"
        popupTitle={materialList.popupTitle}
        data={materialList}
      />
    </div>
    {/* <TablePopup 
        buttonLabel="Comparison of different filter bag materials properties"
        popupTitle={materialList.popupTitle}
        data={materialList}
    /> */}
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4>Product Specifications</h4>
            <div className='table-responsive'>
                <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">Filter</th>
                    <th scope="col">Material</th>
                    <th scope="col">Ring</th>
                    <th scope="col">Type</th>
                    <th scope="col">Aperture</th>
                    <th scope="col">Size</th>
                    <th scope="col">Temperature Resistance</th>
                    <th scope="col">Acid Resistance</th>
                    <th scope="col">Alkali Resistance</th>
                    </tr>
                </thead>
                <tbody>
                    {filterTable.map((product, index) => (
                    <tr key={index}>
                        <td>{product.title}</td>
                        <td>
                        {Array.isArray(product.material) ? (
                            product.material.map((material, i) => (
                            <span key={i}>{material}<br /></span>
                            ))
                        ) : (
                            product.material
                        )}
                        </td>
                        <td>{product.ring}</td>
                        <td>{product.type}</td>
                        <td>
                        {Array.isArray(product.aperture) ? (
                            product.aperture.map((aperture, i) => (
                            <span key={i}>{aperture}<br /></span>
                            ))
                        ) : (
                            product.aperture
                        )}
                        </td>
                        <td>
                        {Array.isArray(product.size) ? (
                            product.size.map((size, i) => (
                            <span key={i}>{size}<br /></span>
                            ))
                        ) : (
                            product.size
                        )}
                        </td>
                        <td>{product.tempRes}</td>
                        <td>{product.acidRes}</td>
                        <td>{product.alkaliRes}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default FilterBag