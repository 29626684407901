import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../../components/filtercard.js'

function CarbonFilter() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'Carbon Rod Activated Carbon filter',
      src:'/images/filters/CarbonRodActivatedCarbonFilter1.png',
      desc:'Mainly used for drinking water, pure water equipment, and internal circulation filtration. Economical activated carbon filter element with high carbon content and long service life. High-efficiency activated carbon is used through continuous highintensity extrusion molding to increase the durability of the filter element. High porosity design prevents premature clogging and effectively removes residual chlorine, odor and organic matter in the liquid.',
      features:[],
      uses:["PCB Industry","Electroplating Industry","RO Pre-filter","Chemical Process","General Water Treatmet Filtration"],
      material:'Carbon',
      innerDiameter:'28mm/30mm',
      outerDiameter:["65mm/70mm"],
      length:'10/20 inches',
      aperture:'5/10µm',
      minTemp:'75°C',
      maxPressure:["0.3Mpa at 20°C"],
    },
    {
      title:'Carbon Cloth Activated Carbon Filter',
      src:'/images/filters/CarbonClothActivatedCarbonFilter2.png',
      desc:'Filters are mainly used in surface treatment, electroplating process, PCB liquid filtration, high-quality carbon cloth enhances the absorption efficiency and the decontamination ability of the filter. It has the dual functions of filtering impurities and absorbing chlorine, odor and color. The high-strength PP polypropylene shaft enhances the structural strength of the filter core.',
      features:[],
      uses:["PCB Industry","Electroplating Industry","Beverage Industry","RO Pre-filter","Chemical Process","General Water Treatmet Filtration"],
      material:'Activated Carbon Cloth',
      innerDiameter:'28mm',
      outerDiameter:["65mm"],
      length:'10/20 inches',
      aperture:'10µm',
      endCap:[""],
      minTemp:'65°C',
      maxPressure:["0.4Mpa at 20°C"],
    },
    {
      title:'Carbon Fiber Activated Carbon Filter',
      src:'/images/filters/CarbonFiberActivatedCarbonFilter3.png',
      desc:'Designed and manufactured for electroplating and chemical industries with high filtration efficiency. Double-effect filter product with high adsorption capacity, with high efficiency of activated carbon adsorption and the function of filtering solid particles, whether high molecular weight or low molecular weight. Its adsorption capacity is 5-10 times higher than that of ordinary filters. It selectively adsorbs impurities, maintains gloss, strengthens gold plating, and stabilizes the quality of silver plating.',
      features:[],
      uses:["PCB Industry","Electroplating Industry","Chemical Process","General Water Treatmet Filtration","RO Pre-filter"],
      material:["Activated Carbon Fiber"],
      innerDiameter:["28mm"],
      outerDiameter:["68mm"],
      length:'10/20 inches',
      aperture:["10µm"],
      endCap:[""],
      minTemp:["65°C"],
      maxPressure:["0.4Mpa at 20°C"],
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Carbon Filters</h3>
                <br/>
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4 id='section1'>Product Specifications</h4>
            <div className='table-responsive'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Filter</th>
                    <th scope="col">Material</th>
                    <th scope="col">Inner Diameter</th>
                    <th scope="col">Outer Diameter</th>
                    <th scope="col">Length</th>
                    <th scope="col">Aperture</th>
                    <th scope="col">pH</th>
                    <th scope="col">End Cap</th>
                    <th scope="col">Maximum Operating Temp</th>
                    <th scope="col">Maximum Opertating Pressure Differences</th>
                  </tr>
                </thead>
                <tbody>
                  {FilterList.map((product, index) => (
                    <tr key={index}>
                      <td>{product.title}</td>
                      <td>
                        {Array.isArray(product.material) ? (
                          product.material.map((material, i) => (
                            <span key={i}>{material}<br /></span>
                          ))
                        ) : (
                          product.material
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.innerDiameter) ? (
                          product.innerDiameter.map((innerDiameter, i) => (
                            <span key={i}>{innerDiameter}<br /></span>
                          ))
                        ) : (
                          product.innerDiameter
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.outerDiameter) ? (
                          product.outerDiameter.map((diameter, i) => (
                            <span key={i}>{diameter}<br /></span>
                          ))
                        ) : (
                          product.outerDiameter
                        )}
                      </td>
                      <td>{product.length}</td>
                      <td>
                        {Array.isArray(product.aperture) ? (
                          product.aperture.map((aperture, i) => (
                            <span key={i}>{aperture}<br /></span>
                          ))
                        ) : (
                          product.aperture
                        )}
                      </td>
                      <td>{product.pH}</td>
                      <td>
                        {Array.isArray(product.endCap) ? (
                          product.endCap.map((endCap, i) => (
                            <span key={i}>{endCap}<br /></span>
                          ))
                        ) : (
                          product.endCap
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.minTemp) ? (
                          product.minTemp.map((minTemp, i) => (
                            <span key={i}>{minTemp}<br /></span>
                          ))
                        ) : (
                          product.minTemp
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.maxPressure) ? (
                          product.maxPressure.map((maxPressure, i) => (
                            <span key={i}>{maxPressure}<br /></span>
                          ))
                        ) : (
                          product.maxPressure
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default CarbonFilter