import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import '../App.js'
import { FaWhatsapp, FaBuilding , FaEnvelope, FaBriefcase } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
  const form = useRef();
  const [modal, setModal] = useState({
    show: false,
    message: '',
    type: ''
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: '',
    countryCode: '',
    contact: '',
    email: '',
    message: '',
  });
  const handleClose = () => setModal({ ...modal, show: false });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaError, setCaptchaError] = useState("");
  const recaptchaRef = useRef(null);

  const validateForm = () => {
    const errors = {
      name: '',
      countryCode: '',
      contact: '',
      email: '',
      message: '',
    };
    
    let isValid = true;
    const name = form.current.name.value.trim();
    const countryCode = form.current.countryCode.value.trim();
    const contact = form.current.contact.value.trim();
    const email = form.current.email.value.trim();
    const message = form.current.message.value.trim();
    if (name === '') {
      errors.name = 'Name cannot be empty';
      isValid = false;
    }
    if (contact === '') {
      errors.contact = 'Contact number cannot be empty';
      isValid = false;
    } else if (!/^\d+$/.test(contact)) {
      errors.contact = 'Contact number must contain only numbers';
      isValid = false;
    }
    if (!/^\+\d{1,4}$/.test(countryCode)) {
      errors.countryCode = 'Country code is invalid';
      isValid = false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
      isValid = false;
    }
    if (message === '') {
      errors.message = 'Message cannot be empty';
      isValid = false;
    }
    if (!captchaVerified) {
      setCaptchaError("Please verify that you're not a robot");
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try{
    setLoading(true);
    emailjs.sendForm('service_6mhul5d', 'template_fcsohlj', form.current, 'Fwqh_ztym-eHIc2rj') 
      .then((result) => {
          setLoading(false);
          setModal({
            show: true,
            message: 'Message sent successfully!',
            type: 'success'
          });
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
          setCaptchaVerified(false);
      }, (error) => {
          setLoading(false);
          setModal({
            show: true,
            message: 'Failed to send the message.',
            type: 'error'
          });
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
          setCaptchaVerified(false);
      });
    e.target.reset();
  } catch(error) {
      console.error('Error:', error);
      setLoading(false);
      setModal({
        show: true,
        message: 'An error occurred. Please try again.',
        type: 'error'
      });
      if (recaptchaRef.current) {
        recaptchaRef.current.reset(); 
      }
      setCaptchaVerified(false);
    }
  };

  const handleCaptcha = (value) => {
    if (value) {
      setCaptchaVerified(true);
      setCaptchaError("");
    }
  };

  return (<>
    <div className="container my-5">
      <div className="row">
        <div className="col-md-6 order-2 order-md-1">
          <h2>Contact Us</h2>
          <form ref={form} onSubmit={sendEmail} className="needs-validation" noValidate>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                id="name"
                name="name"
                required
              />
              <div className="invalid-feedback">
                {formErrors.name}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="company" className="form-label">Company name</label>
              <input
                type="text"
                className={`form-control ${formErrors.company ? 'is-invalid' : ''}`}
                id="company"
                name="company"
                required
              />
              <div className="invalid-feedback">
                {formErrors.company}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="contact" className="form-label">Contact number</label>
              <div className="input-group">
                <input
                  type="text"
                  className={`form-control ${formErrors.countryCode ? 'is-invalid' : ''}`}
                  id="countryCode"
                  name="countryCode"
                  placeholder="+1"
                  defaultValue="+65"
                  required
                />
                <input
                  type="text"
                  className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                  id="contact"
                  name="contact"
                  placeholder="12345678"
                  required
                />
              </div>
              <div className="invalid-feedback d-block">
                {formErrors.countryCode || formErrors.contact}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                id="email"
                name="email"
                required
              />
              <div className="invalid-feedback">
                {formErrors.email}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                id="message"
                name="message"
                rows="4"
                required
              ></textarea>
              <div className="invalid-feedback">
                {formErrors.message}
              </div>
            </div>
            <ReCAPTCHA
              sitekey="6LcDKEsqAAAAANv2L-DkjzSX0tF2F7YBNlCoSnF8"
              onChange={handleCaptcha}
              ref={recaptchaRef}
            />
            {captchaError && (
              <div style={{ color: 'red', marginTop: '5px' }}>
                {captchaError}
              </div>
            )}
            <br/>
            <button type="submit" className="btn btn-primary">Send</button>
          </form>
          <br/>
        </div>
        <div className="col-md-6 order-1 order-md-2">
          <h2>Company Information</h2>
          <div className='fs-4'>
              <div className="social-item d-flex align-items-center mb-2">
                <div className="me-2">
                  <FaBriefcase className="fs-1" />
                </div>
                <span className="social-text ms-2" style={{color:"#666666"}}> Tradeit Pte Ltd</span>
              </div>
              <div className="social-item d-flex align-items-center mb-2">
                <a href="https://wa.me/80207802" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp" style={{color:"#000000", display: 'flex', alignItems: 'center'}}>
                    <div className="me-2">
                      <FaWhatsapp className="fs-1" />
                    </div>
                    <span className="social-text ms-2" style={{color:"#666666"}}> +65 80207802</span>
                </a>
              </div>
              <div className="social-item d-flex align-items-center mb-2">
                <a href="https://maps.app.goo.gl/PKiPi4dpPmwDU4Ft8" target="_blank" rel="noopener noreferrer" aria-label="Google Maps" style={{color:"#000000", display: 'flex', alignItems: 'center'}}>
                  <div className="me-2">
                    <FaBuilding  className="fs-1" style={{color:"#000000",textAlign:'center'}}/>
                  </div>
                  <span className="social-text ms-2" style={{color:"#666666"}}> 60 Paya Lebar Road #06-36 Paya Lebar Square, S(409051), Singapore</span>
                </a>
              </div>
              <div className="social-item d-flex align-items-center">
                <a href="mailto:support@tradeitsg.com" target="_blank" rel="noopener noreferrer" aria-label="Email" style={{color:"#000000", display: 'flex', alignItems: 'center'}}>
                    <div className="me-2">
                      <FaEnvelope className="fs-1" style={{color:"#000000"}}/>
                    </div>
                    <span className="social-text ms-2" style={{color:"#666666"}}> support@tradeitsg.com</span>
                </a>
              </div>
          </div>
          <br/><br/>
        </div>
      </div>
      <div className={`modal fade ${loading ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Sending...</h5>
            </div>
            <div className="modal-body">
              <p>Please wait while we send your message.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${modal.show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog" role="document">
          <div className={`modal-content ${modal.type === 'success' ? 'bg-success text-white' : 'bg-danger text-white'}`}>
            <div className="modal-header">
              <h5 className="modal-title">{modal.type === 'success' ? 'Success' : 'Error'}</h5>
            </div>
            <div className="modal-body">
              <p>{modal.message}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" onClick={handleClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Contact

