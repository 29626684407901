import '../../App.js'
import { Button } from 'react-bootstrap';

function Consultation() {
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <h3>Consult Us</h3>
                <br/>
                <h5 className='fw-normal'>Technical consultancy for customised parts and technical service support.<br/>Engineering expertise to suit your production and processes requirement.<br/>Offer alternative design options with competitive pricing to help in yield output to mitigate quality escalations.</h5>
                <br/>
                <h5 className='fw-normal'>Feel free to Whatsapp, Call or Email us below</h5>
                <Button variant='primary' href='/contact'>Contact Us for more information</Button>
                <br/><br/>
            </div>
        </div>
    </div>
    </>
  )
}

export default Consultation