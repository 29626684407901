import '../App.js'
import { Button } from 'react-bootstrap';

function Service() {
  const serviceList=[
        {url:'./servicesReclaimScraps', buttonLabel:'Handling scraps/Reclaim of precious metals'},
        {url:'./electronicSparesRepair', buttonLabel:'Repair Electronic Spares'},
        {url:'./consultation', buttonLabel:'Consultation'}
  ]    
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-6'>
                <h3>List of services we offer</h3>
                <br/>
                <ul className="list-group list-group-numbered">
                    {serviceList.map((item, index) => (
                        <Button 
                            className='text-start mb-1'
                            key={index} 
                            href={item.url}
                        >
                            {item.buttonLabel}
                        </Button>
                    ))} 
                </ul>
                <br/>
                <br/><br/>
            </div>
        </div>
    </div>
    </>
  )
}

export default Service