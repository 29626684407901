import { Link } from 'react-router-dom';
import './navbar.css';

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <a className="navbar-brand fs-3" href="./">
                        <img src="/images/TradeitLogo600.jpg" alt="Logo" width="60" height="60" className="d-inline-block align-text-center" />
                    </a>
                    <a className="navbar-brand fs-3 ms-2" href="./">
                        <h3 className='me-2 mb-0'>Tradeit Pte Ltd</h3>
                        <h6 className='me-2 mb-0'>联合贸易私人有限公司</h6>
                    </a>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-start" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/about-us">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contact">Contact</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Products
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/chillerUnits">Chiller Units</a></li>
                                <li><a className="dropdown-item" href="/dryPumpUnits">Dry Pumps</a></li>
                                <li><a className="dropdown-item" href="/filterUnits">Filtration Solutions</a></li>
                                <li><a className="dropdown-item" href="/electronicMaterials">Electronics Materials List</a></li>
                                <li><a className="dropdown-item" href="/valvefittings">Valve and Fittings</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/servicesReclaimScraps">Handling scraps/Reclaim</a></li>
                                <li><a className="dropdown-item" href="/electronicSparesRepair">Repair electronic spares</a></li>
                                <li><a className="dropdown-item" href="/consultation">Consultation</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
export default NavBar;