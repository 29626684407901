import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../../components/filtercard.js';

function PleatedFilters() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'PP Microporous Pleated Filter',
      src:'/images/filters/PPMicroporousPleatedFilter1.png',
      desc:'The ultra-fine fiber membrane and the inner and outer supporting layers are folded together. The center rod of the outer shell and the end cap are processed and formed by hot-melt welding technology. It does not contain any adhesive, has no leakage, and no secondary pollution. The filter core adopts a folded type, with a large membrane filtration area, large impurity capacity, low pressure difference, long service life, and wide chemical compatibility.',
      features:[],
      uses:["Filtration of acid and alkali solutions, solvents and etc","Photoresist, developer and etc","End filtration of ink, coating, printing ink, adhesive and etc"],
      material:'Polypropylene',
      innerDiameter:'28mm',
      outerDiameter:["69mm"],
      length:'5 to 40 inch',
      aperture:'0.1 to 50µm',
      pH:'1 to 13',
      endCap:["Double Opening","2-222 tail/rocket end","2-226 horizontal/rocket end"],
      minTemp:'80°C',
      maxPressure:["0.4Mpa at 20°C","0.2Mpa at 80°C"],
    },
    {
      title:'PES Microporous Pleated Filter',
      src:'/images/filters/PESMicroporousPleatedFilter2.png',
      desc:'',
      features:[],
      uses:["Ultrapure water preparation filtration","Final filtration of high purity chemicals."],
      material:'Polyether Sulfate (PES)',
      innerDiameter:'',
      outerDiameter:[""],
      length:'',
      aperture:'0.05 to 1.2µm',
      pH:'2 to 13',
      endCap:[""],
      minTemp:'80°C',
      maxPressure:["0.4Mpa at 20°C","0.2Mpa at 80°C"],
    },
    {
      title:'PTFE Hydrophobic Pleated Filter',
      src:'/images/filters/PTFEHydrophobicPleatedFilter3.png',
      desc:'',
      features:[],
      uses:["Ultrapure water preparation terminal filtration","Final filtration of high purity chemicals such as ketones, lipids, alcohols, etc"],
      material:["Fiber Polypropylene","Degreased cotton thread, glass fiber","Frame: Polypropylene, stainless steel"],
      innerDiameter:[],
      outerDiameter:[""],
      length:'',
      aperture:["Liquid 0.1µm/0.22µm/0.45µm","Gas 0.01µm,0.02µm"],
      pH:'1 to 14',
      endCap:[""],
      minTemp:["90°C"],
      maxPressure:["0.4Mpa at 20°C","0.2Mpa at 90°C"],
    },
    {
      title:'PTFE Hydrophilic Pleated Filter',
      src:'/images/filters/PTFEHydrophilicPleatedFilter4.png',
      desc:'',
      features:[],
      uses:["High temperature liquid filtration","Highly corrosive liquid filtration","Sterile APL, etc Sterilisation and Filtration"],
      material:'Polytetrafluoroethlene (PTFE)',
      innerDiameter:'',
      outerDiameter:[""],
      length:'',
      aperture:'0.22µm/0.45µm',
      pH:'1 to 14',
      endCap:[""],
      minTemp:'90°C',
      maxPressure:["0.4Mpa at 20°C","0.2Mpa at 90°C"],
    },
    {
      title:'PVDF Microporous Pleated Filter',
      src:'/images/filters/PVDFMicroporousPleatedFilter5.png',
      desc:'',
      features:[],
      uses:["Strong solvent filtration","High viscosity liquid filtration","Strong oxidizing liquid filtration","High temperature liquid filtration"],
      material:["Polyvinylidene Fluoride (PVDF)"],
      innerDiameter:[],
      outerDiameter:[""],
      length:'',
      aperture:["0.1µm/0.22µm/0.45µm"],
      pH:'1 to 13',
      endCap:[""],
      minTemp:["90°C"],
      maxPressure:["0.4Mpa at 20°C","0.2Mpa at 90°C"],
    },
    {
        title:'Nylon Microporous Pleated Filter',
        src:'/images/filters/NylonMicroporousPleatedFilter6.png',
        desc:'',
        features:[],
        uses:["Alkaline solvent filtration, benzene, ketone, ether and other chemical filtration"],
        material:["Nylon"],
        innerDiameter:[],
        outerDiameter:[""],
        length:'',
        aperture:["0.1µm to 0.5µm"],
        pH:'6 to 14',
        endCap:[""],
        minTemp:["80°C"],
        maxPressure:["0.4Mpa at 20°C","0.2Mpa at 80°C"],
    },
    {
        title:'Extra large flow pleated filter element',
        src:'/images/filters/ExtraLargeFlowPleatedFilterElement7.png',
        desc:'The filter element is open at one end, and the fluid flows from the inside to the outside. The large diameter design of the filter element increases the effective filtration area, which can greatly reduce the number of filters and reduce the size of the outer shell. In many applications, due to the large flow rate and long filtration life, it can reduce equipment investment and labor costs.',
        features:[],
        uses:["Chemical filtration","Power plant condensate filtration","Paint and coating filtration","R.O security filter","Desalination pretreatment"],
        material:["Glass Fiber (PP)"],
        innerDiameter:[],
        outerDiameter:["152mm"],
        length:'20/40/60 inches',
        aperture:["1 to 100µm"],
        pH:'',
        endCap:[""],
        minTemp:["80°C (PP)"],
        maxPressure:["0.24Mpa at 20°C"],
    },
    {
        title:'High Flow Rate Pleated Filter',
        src:'/images/filters/HighFlowRatePleatedFilter8.png',
        desc:'',
        features:[],
        uses:["PCW Filtration","RO pre-filter","Chemical Process","R.O security filter","Beverage Industry"],
        material:["Glass Fiber (PP)"],
        innerDiameter:["28mm"],
        outerDiameter:["70mm/115mm"],
        length:'10 to 40 inches',
        aperture:["1 to 50µm"],
        pH:'',
        endCap:[""],
        minTemp:["60°C"],
        maxPressure:["0.4Mpa at 20°C"],
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Pleated Filters</h3>
                <br/>
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4 id='section1'>Product Specifications</h4>
            <div className='table-responsive'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Filter</th>
                    <th scope="col">Material</th>
                    <th scope="col">Inner Diameter</th>
                    <th scope="col">Outer Diameter</th>
                    <th scope="col">Length</th>
                    <th scope="col">Aperture</th>
                    <th scope="col">pH</th>
                    <th scope="col">End Cap</th>
                    <th scope="col">Minimum Operating Temp</th>
                    <th scope="col">Maximum Opertating Pressure Differences</th>
                  </tr>
                </thead>
                <tbody>
                  {FilterList.map((product, index) => (
                    <tr key={index}>
                      <td>{product.title}</td>
                      <td>
                        {Array.isArray(product.material) ? (
                          product.material.map((material, i) => (
                            <span key={i}>{material}<br /></span>
                          ))
                        ) : (
                          product.material
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.innerDiameter) ? (
                          product.innerDiameter.map((innerDiameter, i) => (
                            <span key={i}>{innerDiameter}<br /></span>
                          ))
                        ) : (
                          product.innerDiameter
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.outerDiameter) ? (
                          product.outerDiameter.map((diameter, i) => (
                            <span key={i}>{diameter}<br /></span>
                          ))
                        ) : (
                          product.outerDiameter
                        )}
                      </td>
                      <td>{product.length}</td>
                      <td>
                        {Array.isArray(product.aperture) ? (
                          product.aperture.map((aperture, i) => (
                            <span key={i}>{aperture}<br /></span>
                          ))
                        ) : (
                          product.aperture
                        )}
                      </td>
                      <td>{product.pH}</td>
                      <td>
                        {Array.isArray(product.endCap) ? (
                          product.endCap.map((endCap, i) => (
                            <span key={i}>{endCap}<br /></span>
                          ))
                        ) : (
                          product.endCap
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.minTemp) ? (
                          product.minTemp.map((minTemp, i) => (
                            <span key={i}>{minTemp}<br /></span>
                          ))
                        ) : (
                          product.minTemp
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.maxPressure) ? (
                          product.maxPressure.map((maxPressure, i) => (
                            <span key={i}>{maxPressure}<br /></span>
                          ))
                        ) : (
                          product.maxPressure
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default PleatedFilters