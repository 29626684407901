import ListItemWithImage from '../../components/imagepopup.js'
import './drypump.css'
import '../../App.js'

function DryPump() {
  const productList=[
    {src:'/images/dryPumps/DryPump1.png', buttonLabel:'Dry Pump', popupTitle:'Dry Pump Sample Image'},
    {src:'/images/dryPumps/VacuumDryPump2.png', buttonLabel:'Vacuum Dry Pump', popupTitle:'Vacuum Dry Pump Sample Image'},
    {src:'/images/dryPumps/Booster3.png', buttonLabel:'Booster', popupTitle:'Booster Sample Image'},
    {src:'/images/dryPumps/IntegratedSystem4.png', buttonLabel:'Integrated System', popupTitle:'Integrated System Sample Image'},
    {src:'/images/dryPumps/KeyParts5.png', buttonLabel:'Key Parts', popupTitle:'Key Parts Sample Image'},
    {src:'/images/dryPumps/MolecularPump6.png', buttonLabel:'Molecular Pump', popupTitle:'Molecular Pump Sample Image'},
  ]
  const serviceList=[
    {src:'/images/dryPumps/PumpOverhaul7.png', buttonLabel:'Pump Overhaul', popupTitle:'Pump Overhaul Sample Image'},
    {src:'/images/dryPumps/ControlSystem8.png', buttonLabel:'Control System', popupTitle:'Control System Sample Image'},
    {src:'/images/dryPumps/PartsCleaning9.png', buttonLabel:'Parts Cleaning', popupTitle:'Parts Cleaning Sample Image'},
    {src:'/images/dryPumps/Oring10.png', buttonLabel:'Oring', popupTitle:'Oring Sample Image'},
  ]
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <h3>Dry Pump For Semiconductor Applications</h3>
                <br/>
                <h5 className='fw-normal'>Supply of new Pumps such as Dry Pump, Vacuum Dry Pump, Molecular Pump and integrated System.<br/>Offer servicing, repair and overhaul of pumps and robotic systems.</h5>
                <br/>
                {/* <h4>Products list</h4> */}
                {/* <ul className='list-group list-group-flush col-6'>
                  {productList.map((item,index)=>(
                    <ListItemWithImage key={index} imageSrc={item.src} buttonLabel={item.buttonLabel} popupTitle={item.popupTitle} />
                    // <li class='list-group-item' key={index}>{item.label}</li>
                    ))}
                </ul>
                <h4>Services list</h4>
                <ul className='list-group list-group-flush col-6'>
                  {serviceList.map((item,index)=>(
                    <ListItemWithImage key={index} imageSrc={item.src} buttonLabel={item.buttonLabel} popupTitle={item.popupTitle} />
                    // <li class='list-group-item' key={index}>{item.label}</li>
                    ))}
                </ul> */}
                {/* <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Product List</h4>
                      <ul className="list-group list-group-flush">
                        {productList.map((item, index) => (
                          <ListItemWithImage 
                            key={index} 
                            imageSrc={item.src} 
                            buttonLabel={item.buttonLabel} 
                            popupTitle={item.popupTitle} 
                          />
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <h4>Services List</h4>
                      <ul className="list-group list-group-flush">
                        {serviceList.map((item, index) => (
                          <ListItemWithImage 
                            key={index} 
                            imageSrc={item.src} 
                            buttonLabel={item.buttonLabel} 
                            popupTitle={item.popupTitle} 
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div className="container my-5">
                  <div className="row">
                    {/* Product List */}
                    <div className="col-md-6 mb-4">
                      <h4 className="mb-3">Product List</h4>
                      <ul className="list-group custom-list">
                        {productList.map((item, index) => (
                          <ListItemWithImage 
                            key={index} 
                            imageSrc={item.src} 
                            buttonLabel={item.buttonLabel} 
                            popupTitle={item.popupTitle} 
                          />
                        ))}
                      </ul>
                    </div>

                    {/* Service List */}
                    <div className="col-md-6 mb-4">
                      <h4 className="mb-3">Services List</h4>
                      <ul className="list-group custom-list">
                        {serviceList.map((item, index) => (
                          <ListItemWithImage 
                            key={index} 
                            imageSrc={item.src} 
                            buttonLabel={item.buttonLabel} 
                            popupTitle={item.popupTitle} 
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <br/><br/>
            </div>
        </div>
    </div>
    </>
  )
}

export default DryPump