import './home.css'
import ImageCarousel from '../components/carousel';

function Home() {
    const containers=[
        {title:'Supply of Materials', description:'Si Wafers (N-type / P-type) available in various sizes – 4″, 6″, 8″, 12″ \n\n Sputtering Targets / Evaporator Pellets (COC provided to ensure integrity of part’s purity and sizes). Available in Au, Ag, C, Cr, Al, Ti, TiW, metal oxides and etc.'}
        ,{title:'Engineering Products', description:'Customized engineering products ranging from Al, Si, Quartz, Ceramics (Alumina) materials. \n\n Applicable in CVD, PVD, Etch processes.'}
        ,{title:'Handling of Scraps',description:'Experienced in handling of electronic scraps that contains sensitive design/pattern. \n\n Provides Certificate of Destruction to ensure disposal/destruction to protect proprietary information.'}
        ,{title:'Consultant',description:'Providing consultation and offering of customization of parts/products through reverse engineering expertise to cater for individual customer’s application needs. \n\n Offering of local capability in electronic spares repair.'}
        ,{title:'Sub-Fab/Facility Management/HVAC equipment',description:'Supply of energy saving chiller units and dry vacuum pumps at competitive pricing. \n\n Offer of service and repair programs for dry pumps and chiller units. \n\n Applicable in biomedical, chemical/gas and semiconductor industry.'}
        ,{title:'Reclaim precious metals',description:'Robust reclaim process with high return yield with report provided. We offer various method to park back reclaim amount value back to our customers. \n\n Reclaim of precious metals (Au, Pt, Ag and etc).'}
    ]
    return (
        <>
            <ImageCarousel/>
            <div className="container">
            <div className="row">
                {containers.map((item, index) => (
                <div key={index} className="col-md-4 mb-4 d-flex">
                    <div className="card p-4 text-start">
                        <span className="asterisk display-1">*</span>
                        <h4 className="title mt-0 fw-bold">{item.title}</h4>
                        <p className="description mt-2">{item.description}</p>
                    </div>
                </div>
                ))}
            </div>
            </div>
        </>
    )
}



export default Home;