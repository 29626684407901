import React from 'react';
import './contactbtn.css';

const ContactBtn = () => {
  const handleClick = () => {
    // alert('Chat button clicked!');
  };

  return (
    <div className="chat-button" onClick={handleClick}>
      <a href='/contact'>
        <span role="img" aria-label="chat">💬</span>
      </a>
    </div>
  );
};

export default ContactBtn;
