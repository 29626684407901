import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../../components/filtercard.js';

function ThickFoldingFilters() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'Thick Folding Filter',
      src:'/images/filters/ThickFoldingFilter1.png',
      desc:'',
      features:[],
      uses:[],
      material:'PP, PES, PTFE,Hydrophilic PTFE',
      innerDiameter:'28mm',
      outerDiameter:["83mm/131mm"],
      length:'10 inch',
      aperture:["PP-0.1 to 50µm","PES-0.22 to 1.2µm","PTFE-0.22 to 10µm","HPTFE-0.5 to 1.0µm"],
      endCap:["222 pairs of seal rings (Dia 83mm)","226 pairs seal ring (Dia 83mm)","334 siingle seal ring (Dia 131mm)"],
      minTemp:["PP/PES-80°C","PTFE-90°C"],
      maxPressure:["0.4Mpa at 20°C"],
    },
    {
      title:'Capsule Filter',
      src:'/images/filters/CapsuleFilter1.png',
      desc:'',
      features:[],
      uses:["Pharmaceutical & Chemical Industry","Bioengineering Industry","Electronics Industry","Comestic process filtration","Food, Beverage, Ink and Gas Filtration"],
      material:["PP, PES, PTFE, Nylon","Filter Shell-PP","Support-PP"],
      innerDiameter:'',
      outerDiameter:["152mm"],
      length:'20/40/60 inches',
      aperture:["PP-0.1 to 50µm","PES-0.05 to 1.2µm","Nylon-0.1 to 1µm","PTFE-0.1µm, 0.22µm, 0.45µm","Gas PTFE-0.01µm, 0.2µm"],
      endCap:[""],
      minTemp:'',
      maxPressure:[],
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Thick Folding/Capsule Filters</h3>
                <br/>
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4 id='section1'>Product Specifications</h4>
            <div className='table-responsive'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Filter</th>
                    <th scope="col">Material</th>
                    <th scope="col">Inner Diameter</th>
                    <th scope="col">Outer Diameter</th>
                    <th scope="col">Length</th>
                    <th scope="col">Aperture</th>
                    {/* <th scope="col">pH</th> */}
                    <th scope="col">End Cap</th>
                    <th scope="col">Maximum Operating Temp</th>
                    <th scope="col">Maximum Opertating Pressure Differences</th>
                  </tr>
                </thead>
                <tbody>
                  {FilterList.map((product, index) => (
                    <tr key={index}>
                      <td>{product.title}</td>
                      <td>
                        {Array.isArray(product.material) ? (
                          product.material.map((material, i) => (
                            <span key={i}>{material}<br /></span>
                          ))
                        ) : (
                          product.material
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.innerDiameter) ? (
                          product.innerDiameter.map((innerDiameter, i) => (
                            <span key={i}>{innerDiameter}<br /></span>
                          ))
                        ) : (
                          product.innerDiameter
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.outerDiameter) ? (
                          product.outerDiameter.map((diameter, i) => (
                            <span key={i}>{diameter}<br /></span>
                          ))
                        ) : (
                          product.outerDiameter
                        )}
                      </td>
                      <td>{product.length}</td>
                      <td>
                        {Array.isArray(product.aperture) ? (
                          product.aperture.map((aperture, i) => (
                            <span key={i}>{aperture}<br /></span>
                          ))
                        ) : (
                          product.aperture
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.endCap) ? (
                          product.endCap.map((endCap, i) => (
                            <span key={i}>{endCap}<br /></span>
                          ))
                        ) : (
                          product.endCap
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.minTemp) ? (
                          product.minTemp.map((minTemp, i) => (
                            <span key={i}>{minTemp}<br /></span>
                          ))
                        ) : (
                          product.minTemp
                        )}
                      </td>
                      <td>
                        {Array.isArray(product.maxPressure) ? (
                          product.maxPressure.map((maxPressure, i) => (
                            <span key={i}>{maxPressure}<br /></span>
                          ))
                        ) : (
                          product.maxPressure
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default ThickFoldingFilters