import React from 'react';
import './footer.css'
import { FaWhatsapp, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

function Footer(){
    return(
        <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
        <footer className="footer">
            <div className="container">
                <div className="footer-sections">
                    <div className="footer-section">
                        <h4>About Us</h4>
                        <p>
                            Leading the industry with innovative solutions and exceptional services.
                            Join us in our journey to success.
                        </p>
                    </div>
                    <div className="footer-section">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a href="./about-us">About Us</a></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/service">Our Services</a></li>
                            <li><a href="/product">Our Products</a></li>
                        </ul>
                    </div>
                    {/* <div className="footer-section">
                        <h4>Contact Us</h4>
                        <div className="social-icons">
                            <a href="https://wa.me/80207802" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                                <FaWhatsapp />
                            </a>
                            <a href="https://maps.app.goo.gl/PKiPi4dpPmwDU4Ft8" target="_blank" rel="noopener noreferrer" aria-label="Google Maps">
                                <FaMapMarkerAlt />
                            </a>
                            <a href="mailto:support@tradeitsg.com" target="_blank" rel="noopener noreferrer" aria-label="Email">
                                <FaEnvelope />
                            </a>
                        </div> style={{textDecoration:'none', color:'#666666'}}
                    </div> */}
                    <div className="footer-section">
                        <h4>Contact Us</h4>
                        <div className='social-info'>
                            <div className="social-item">
                                <a href="https://wa.me/80207802" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                                    <FaWhatsapp className="social-icon" />
                                    <span className="social-text"> +65 80207802</span>
                                </a>
                            </div>
                            <div className="social-item">
                                <a href="https://maps.app.goo.gl/PKiPi4dpPmwDU4Ft8" target="_blank" rel="noopener noreferrer" aria-label="Google Maps">
                                    <FaMapMarkerAlt className="social-icon" />
                                    <span className="contact-text"> 60 Paya Lebar Road #06-36 Paya Lebar Square, S(409051), Singapore</span>
                                </a>
                            </div>
                            <div className="social-item">
                                <a href="mailto:support@tradeitsg.com" target="_blank" rel="noopener noreferrer" aria-label="Email">
                                    <FaEnvelope className="social-icon" />
                                    <span className="social-text"> support@tradeitsg.com</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Tradeit Pte Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
        </>
    )
};

export default Footer;