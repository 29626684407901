import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../../components/filtercard.js'

function AirCompressor() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'Air Compressor Filter',
      src:'/images/filters/AirCompressorFilter1.png',
      desc:'',
      features:[],
      uses:[],
      material:'Carbon',
      innerDiameter:'28mm/30mm',
      outerDiameter:["65mm/70mm"],
      length:'10/20 inches',
      aperture:'5/10µm',
      minTemp:'75°C',
      maxPressure:["0.3Mpa at 20°C"],
    },
  ]
  const tableFilter=[
    {
      title:'Model',
      domnick:'K009~K620',
      donaldson:'02/05~30/50',
      zander:'1030~3075',
      hankinson:'12~48',
      hiross:'004~240',
      parker:'10-25~85-360',
      bea:'30~1400',
      smc:'150~850',
    },
    {
        title:'Filter Level',
        domnick:'PF, AO, AA, AX, ACS, AR, AAR',
        donaldson:'PE, SB, FF, MF, SMF, AK',
        zander:'V, Z, Y, X, A',
        hankinson:'E1, E3, E5, E7, E9',
        hiross:'Q, P, S, C',
        parker:'C, QU, P, AU',
        bea:'RB, RA, CA, RF',
        smc:'AM, AME, AMD, AFF, AMF',
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Air Compressor</h3>
                <br/>
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4 id='section1'>Product Specifications</h4>
            <div className='table-responsive'>
                <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">Brand</th>
                    <th scope="col">Domnick Hunter</th>
                    <th scope="col">Donaldson (Ultrafilter)</th>
                    <th scope="col">Zander</th>
                    <th scope="col">Hankinson</th>
                    <th scope="col">Hiross</th>
                    <th scope="col">Parker</th>
                    <th scope="col">BEA</th>
                    <th scope="col">SMC</th>
                    </tr>
                </thead>
                <tbody>
                    {tableFilter.map((product, index) => (
                    <tr key={index}>
                        <td>{product.title}</td>
                        <td>{product.domnick}</td>
                        <td>{product.donaldson}</td>
                        <td>{product.zander}</td>
                        <td>{product.hankinson}</td>
                        <td>{product.hiross}</td>
                        <td>{product.parker}</td>
                        <td>{product.bea}</td>
                        <td>{product.smc}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default AirCompressor