import './chillerunits.css'
// import ListItemWithImage from '/src/components/ImagePopup.jsx'
import TablePopup from '../../components/tablepopup.js'
import '../../App.js'


function ChillerUnits() {
    const chartItems = [
        {
          title: 'Etch tools',
          image: '/images/chillerUnits/ChillerEtch1.png',
          buttonLabel: 'Learn More',
          src:'src/assets/ChillerUnits/ChillerEtch1.png',
          popupTitle:'Sample specifications for Chiller Units (Etch Tools)',
          data:[
            {
              Model:'E1',
              Range:'~80°C~+40°C',
              Accuracy:'±0.1°C',
              CoolingCapacity:'5kw@~30°C',
              Size:'500*1000*1700',
            },
            {
              Model:'E2',
              Range:'~10°C~+60°C/-20°C~+80°C',
              Accuracy:'±0.1°C',
              CoolingCapacity:'10kw@~20°C',
              Size:'Single: 400*900*1500/Multi: 700*1000*1730',
            },
            {
              Model:'E3',
              Range:'0°C~+60°C',
              Accuracy:'±0.01°C',
              CoolingCapacity:'6kw@~20°C',
              Size:'470*470*380',
            },
            {
              Model:'E4',
              Range:'+40°C~+220°C',
              Accuracy:'±0.1°C',
              CoolingCapacity:'2kw@~50°C',
              Size:'500*500*1200',
            },
          ]
        },
        {
          title: 'CVD tools',
          image: '/images/chillerUnits/ChillerCVD1.png',
          buttonLabel: 'Learn More',
          src:'src/assets/ChillerUnits/ChillerEtch1.png',
          popupTitle:'Sample specifications for Chiller Units (CVD Tools)',
          data:[
            {
              Model:'C1',
              Range:'+30°C~+90°C',
              Accuracy:'±0.1°C',
              CoolingCapacity:'15kw@~50°C',
              Size:'430*900*1500',
            },
            {
              Model:'C2',
              Range:'+40°C~+180°C',
              Accuracy:'±0.1°C',
              CoolingCapacity:'8kw@~50°C',
              Size:'400*900*1500',
            },
            {
              Model:'C3',
              Range:'+30°C~+90°C',
              Accuracy:'±0.01°C',
              CoolingCapacity:'6kw@~50°C',
              Size:'240*400*500',
            },
          ]
        },
        {
          title: 'PVD tools',
          image: '/images/chillerUnits/ChillerPVD1.png',
          buttonLabel: 'Learn More',
          src:'src/assets/ChillerUnits/ChillerEtch1.png',
          popupTitle:'Sample specifications for Chiller Units (PVD Tools)',
          data:[
            {
              Model:'P1',
              Range:'+15°C~+60°C',
              Accuracy:'±0.3°C',
              CoolingCapacity:'30kW~240kW',
              Size:'1000*1000*1700',
            },
            {
              Model:'P2',
              Range:'+40°C~+180°C',
              Accuracy:'±0.2°C',
              CoolingCapacity:'8kW@50°C',
              Size:'400*900*1500',
            },
          ]
        },
        {
          title: 'Lithography',
          image: '/images/chillerUnits/ChillerLitho1.png',
          buttonLabel: 'Learn More',
          src:'src/assets/ChillerUnits/ChillerEtch1.png',
          popupTitle:'Sample specifications for Chiller Units (Lithography)',
          data:[
            {
              Model:'L1',
              Range:'+16°C~+28°C',
              Accuracy:'±0.01°C',
              CoolingCapacity:'2kW',
              Size:'400*500*550',
            }
          ]
        },
    ];
  return (
    <>
        <div className='container-md text-start mt-5'>
            <div className='row'>
                <div className='col-12 col-md-9'>
                    <h3>Chiller For Semiconductor Applications</h3>
                    <br/>
                    <h5 className='fw-normal'>Supply of energy savings Chiller for semiconductor production tools.<br/>Different models available to cater to different fab modules such as Etch, CVD, PVD and Lithography.<br/>Our Chiller is compatible to use with different OEM tools commonly used in the market.</h5>
                </div>
            </div>
        </div>
        <br/>
        <div className="container chart-container py-5">
            <div className="row">
                {chartItems.map((item, index) => (
                <div key={index} className="col-md-6 col-lg-3 text-center mb-4">
                    <div className="chart-item p-4">
                    <h3 className="title mb-3">{item.title}</h3>
                    <img src={item.image} alt={item.title} className="img-fluid mb-3 chart-image" />
                    <br/>
                    <TablePopup 
                      buttonLabel={item.buttonLabel}
                      popupTitle={item.popupTitle}
                      data={item.data}
                    />
                    </div>
                </div>
                ))}
            </div>
        </div>
        <br/><br/>
    </>
  )
}

export default ChillerUnits