import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import './carousel.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = () => {
  const sliderRef = useRef(null);
  const [countdown, setCountdown] = useState(10);
  const intervalRef = useRef(null);
  const startTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          sliderRef.current.slickNext();
          return 10;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();

    return () => clearInterval(intervalRef.current);
  }, []);
  const handleBeforeChange = () => {
    setCountdown(10);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    beforeChange: () => handleBeforeChange(),
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        <div className="slide">
          <div className="homeTitleContainer">
            <div className="homeTitlePara">
              <h1>A commitment to innovation and sustainability to achieve high customers satisfaction</h1>
            </div>
            <div className="homeTitlePara2">
              <p>Tradeit Pte Ltd is a Singapore incorporated company in the year of 2019 with the objectives of becoming a valued supplier in electronic materials supply, robotic automation, and recycling service provider.</p>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="homeTitleContainer2">
            <div className="homeTitlePara">
              <h1>One Stop Solution Provider</h1>
            </div>
            <div className="homeTitlePara2">
              <p>Our comprehensive suite of products and services caters to various industries from chemical, biomedical, semiconductor to electronics manufacturing.</p>
            </div>
          </div>
        </div>
      </Slider>
      <div className="timer">
        <div className="timer-bar" style={{ width: `${(countdown / 10) * 100}%` }}></div>
      </div>
    </div>
  );
};

export default ImageCarousel;
