import '../../App.js'
import { Button } from 'react-bootstrap';
import './filterunits.css'

function FilterUnits() {
  const chartItems = [
    {
        title:'Melt Filters',
        image:'/images/filters/PPMeltSprayFilter1.png',
        buttonLabel:'Learn More',
        src:'./meltFilters',
    },
    {
        title:'Pleated Filters',
        image:'/images/filters/PPMicroporousPleatedFilter1.png',
        buttonLabel:'Learn More',
        src:'./pleatedFilters',
    },
    {
        title:'Thick Folding/Capsule Filters',
        image:'/images/filters/ThickFoldingFilter1.png',
        buttonLabel:'Learn More',
        src:'./thickFoldingFilters',
    },
    {
        title:'Carbon Filters',
        image:'/images/filters/CarbonRodActivatedCarbonFilter1.png',
        buttonLabel:'Learn More',
        src:'./carbonFilters',
    },
    {
        title:'Filter Element',
        image:'/images/filters/PlanarSinteredFilterElement1.png',
        buttonLabel:'Learn More',
        src:'./filterElement',
    },
    {
        title:'Filter Bag',
        image:'/images/filters/NonWovenFilterBag1.png',
        buttonLabel:'Learn More',
        src:'./filterBag',
    },
    {
        title:'Air Compressor',
        image:'/images/filters/AirCompressorFilter1.png',
        buttonLabel:'Learn More',
        src:'./airCompressor',
    },
  ]
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <h3>Filtration Solutions</h3>
                <br/>
                <h5 className='fw-normal'>Our range of filter solutions to cater for chemical, gas and water treatment plant.<br/>Applicable to use in chemical, gas, biomedical and semiconductor industry.</h5>
                <br/>
            </div>
        </div>
    </div>
    <br/>
    <div className="container chart-container py-5">
        <div className="row">
            {chartItems.map((item, index) => (
            <div key={index} className="col-md-6 col-lg-3 text-center mb-4">
                <div className="chart-item p-4 d-flex flex-column justify-content-between h-100">
                <h3 className="title mb-3">{item.title}</h3>
                <img src={item.image} alt={item.title} className="img-fluid mb-3 chart-image" style={{ objectFit: 'contain', width: '100%', height: 'auto' }}/>
                <br/>
                <Button
                className="btn btn-primary chart-btn" 
                key={index} 
                href={item.src}
                >
                    {item.buttonLabel}
                </Button>
                </div>
            </div>
            ))}
        </div>
    </div>
    <br/><br/>
    </>
  )
}

export default FilterUnits