import '../../App.js'

function ElectronicSparesRepair() {
  return (
    <>
    <div className='container-md text-start mt-5'>
    <div className='row'>
        <div className='col-12 col-md-7'>
            <h3>Electronic Spares Repair Capability</h3>
            <br/><br/>
            <h4 className='fw-bold'>Technical team with experience and capability on semicon parts repair.</h4>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">HV power supply/Transformer/Power amplifier/Power supply</li>
                <li className="list-group-item">Controller/PCB board/Driver/Screen</li>
                <li className="list-group-item">Industrial Computer/Single Board Computer</li>
                <li style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }} className="list-group-item">Parts for most of OEM tools including <br/>AMAT/ASML/Lam/Tel/Varian/Nissin/DNS/Nikon/Murata/Daifuku</li>
            </ul>
            <br/><br/>
            <h4 className='fw-bold'>Capability of test platform for HV power supply/driver/SBC</h4>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Capable to test HV power supply up to 10kw power load</li>
                <li className="list-group-item">Developed motor driver testing platform to test / certify repaired parts</li>
                <li className="list-group-item">Self-test and on-tool test for PGU units/turbo pump controllers</li>
                <li className="list-group-item">Reverse-engineering for obsoleted and unrepairable parts</li>
            </ul>
            <br/><br/>
            <h4 className='fw-bold'>Parts Repair List</h4>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Power Supply Assembly, Multi-pole, Extraction, 90KV, Rod Controller Assy, Ext Supp HV 40KV/25mA</li>
                <li className="list-group-item">Servo Motor Driver 20W 24VDC Analog/Servo Amplifier 100W 200V 1PHLEGEN</li>
                <li className="list-group-item">MAGLEV Controller (Black)</li>
                <li className="list-group-item">PCB for Lamp Failure Detection Board</li>
                <li className="list-group-item">15 zone SCR Driver</li>
                <li className="list-group-item">Model 3100 O2 Analyzer</li>
                <li className="list-group-item">Driver, 250KEV, 10mA, GHV</li>
                <li className="list-group-item">Magnet Power Supply</li>
                <li className="list-group-item">Electrical Assy, Integrated Simplified Controller</li>
                <li className="list-group-item">ESC / AMU Power Supply / Beam Gate</li>
            </ul>
            <br/><br/>
        </div>
        <div className='col-12 col-md-4'>
            <div className='d-flex flex-column align-items-center'>
                <img src='/images/ESpareRepair1.png' alt='Image 1' className='img-fluid mb-3' />
                <img src='/images/ESpareRepair2.png' alt='Image 2' className='img-fluid mb-3' />
                <img src='/images/ESpareRepair3.png' alt='Image 3' className='img-fluid mb-3' />
                <img src='/images/ESpareRepair4.png' alt='Image 4' className='img-fluid mb-3' />
                <img src='/images/ESpareRepair5.png' alt='Image 5' className='img-fluid mb-3' />
                <img src='/images/ESpareRepair6.png' alt='Image 5' className='img-fluid mb-3' />
            </div>
        </div>
    </div>
</div>

    </>
  )
}

export default ElectronicSparesRepair