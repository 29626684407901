import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TablePopup = ({ data, buttonLabel, popupTitle }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const formatHeader = (header) => {
    return header.replace(/([a-z])([A-Z])/g, '$1 $2');
  };
    const getColumnHeaders = () => {
        if (data.length === 0) return [];
        const columns = new Set();
        data.forEach(row => {
        Object.keys(row).forEach(key => {
            if (key !== 'popupTitle') { 
            columns.add(key);
            }
        });
        });
        return Array.from(columns);
    };

  const columnHeaders = getColumnHeaders();

  return (
    <>
      <li className="list-group-item">
        <Button variant="primary" onClick={handleShow}>
          {buttonLabel}
        </Button>
      </li>

      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            {data.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    {columnHeaders.map((header, index) => (
                      <th scope="col" key={index}>
                        {formatHeader(header)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {columnHeaders.map((header, colIndex) => (
                        <td key={colIndex}>{row[header] || 'N/A'}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TablePopup;
