import { FaArrowRight } from 'react-icons/fa';
import './drypump.css'
import '../../App.js'

function valveFitting() {
  const regulatorsList=[
    {name:'Cylinder Pressure Regulator 1/4"'},
    {name:'High Flow Regulator 1/2"'},
    {name:'High Flow Regulator 3/4"'},
    {name:'Dual Stage Regulator1/4"'},
    {name:'High Pressure Regulator'},
    {name:'High Flow Regulator 1"'},
    {name:'Back Pressure Regulator 1/4"'},
    {name:'High Flow Regulator 1-1/2"'},
    {name:'High Flow Regulator 2"'},
    {name:'PRM Panel Regulator'},
    {name:'PMF Panel Regulator'},
    {name:'Semi-automatic Changeover System'},
    {name:'PMD Panel Regulator '},
    {name:'PRD Panel Regulator'},
    {name:'Diaphragm Shut-off Valve'},
  ]
  const accessoriesList=[
    {name:'Instrumentation Valves'},
    {name:'Cylinder Connectors'},
    {name:'Metal Flexible Hoses'},
    {name:'Supper Clean Tube '},
    {name:'Coil Tube'},
    {name:'Quick Connection'},
    {name:'Sample Cylinder'},
  ]
  const description=[
    {text:'With engineering, production, assembling and testing under one roof, we manufacture valves you urgently need in a very limited time frame. Flexible at all times, especially for emergency valve requirements.'}
  ]
  const headingList=[
    {name:'Inconel Fittings'},
    {name:'Hastelloy Manifolds Valves'},
    {name:'Monel Fittings'},
    {name:'Hastelloy Ball Valves'},
  ]
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-8'>
                <h3>Valve and Fittings</h3>
                <br/>
                <h5 className='fw-normal'>{description[0].text}</h5>
                <ul className="list-group list-group-flush">
                    {headingList.map((item, index) => (
                        <li key={index} className="list-group-item"><FaArrowRight className="me-2" />{item.name}</li>
                    ))}
                </ul>
                <br/>
                <div className="container my-5">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <h4 className="mb-3">Regulators List</h4>
                      <ul className="list-group custom-list">
                        {regulatorsList.map((item, index) => (
                          <li key={index} className="list-group-item">{item.name}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-6 mb-4">
                      <h4 className="mb-3">Accessories List</h4>
                      <ul className="list-group custom-list">
                        {accessoriesList.map((item, index) => (
                          <li key={index} className="list-group-item">{item.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <br/><br/>
            </div>
            <div className='col-12 col-md-3'>
                <div className='d-flex flex-column align-items-center'>
                    <img src='/images/Valve1.png' alt='Valve Image 1' className='img-fluid mb-3' />
                    <img src='/images/Valve2.png' alt='Valve Image 2' className='img-fluid mb-3' />
                    <img src='/images/Valve3.png' alt='Valve Image 3' className='img-fluid mb-3' />
                    <img src='/images/Valve4.png' alt='Valve Image 4' className='img-fluid mb-3' />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default valveFitting