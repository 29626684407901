import React from 'react';

const GoogleMaps = ({ gMapUrl, locationDetails }) => {
    return(
        <>
        <div className='row justify-content-center mt-5'>
          <div className='col-12 col-md-6 mb-3'>
            <div className='map-container mb-4'>
              <iframe 
                src={gMapUrl}
                width="100%" 
                height="400" 
                style={{ border: 0 }}
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <h5 className="text-center text-md-start custom-sm-fs custom-md-fs fw-normal" dangerouslySetInnerHTML={{ __html: locationDetails }} style={{color:"#666666"}}></h5>
          </div>
        </div>
        </>
    )  
};

export default GoogleMaps;
    