import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ListItemWithImage = ({ imageSrc, buttonLabel, popupTitle }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <li className="list-group-item">
        <Button variant="primary" onClick={handleShow} >
          {buttonLabel}
        </Button>
      </li>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imageSrc} alt={popupTitle} className="img-fluid popupImg"/>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ListItemWithImage;
