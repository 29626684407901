import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//Components
import NavBar from './components/navbar';
import ContactBtn from './components/contactbtn.js';
import ScrollToTopBtn from './components/scrolltotopbtn.js';
import Footer from './components/footer.js';
//Pages
import Home from './pages/home';
import AboutUs from './pages/aboutus.js';
import Contact from './pages/contact.js';
import ServiceList from './pages/service.js';
import ProductList from './pages/product.js';
//Pages Products
import ChillerUnits from './pages/product/chillerunits.js';
import DryPump from './pages/product/drypump.js';
import FilterUnits from './pages/product/filterunits.js';
import MeltFilters from './pages/product/filters/meltfilters.js';
import PleatedFilters from './pages/product/filters/pleatedfilters.js';
import ThickFoldingFilters from './pages/product/filters/thickfoldingfilters.js';
import CarbonFilters from './pages/product/filters/carbonfilters.js';
import FilterElement from './pages/product/filters/filterelement.js';
import FilterBag from './pages/product/filters/filterbag.js';
import AirCompressor from './pages/product/filters/aircompressor.js';
import ElectronicMaterials from './pages/product/electronicmaterials.js';
import ValveFitting from './pages/product/valvefitting.js'
//Pages Services
import HandleReclaimScraps from './pages/services/handlereclaimscraps.js'
import Consultation from './pages/services/consultation.js'
import ElectronicSparesRepair from './pages/services/electronicsparesrepair.js'

import './App.css';

function App() {
  return (
    <>
    <Router>
      <NavBar/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service" element={<ServiceList />} />
          <Route path="/product" element={<ProductList />} />
          <Route path="/chillerUnits" element={<ChillerUnits />} />
          <Route path="/dryPumpUnits" element={<DryPump />} />
          <Route path="/filterUnits" element={<FilterUnits />} />
          <Route path="/meltFilters" element={<MeltFilters />} />
          <Route path="/pleatedFilters" element={<PleatedFilters />} />
          <Route path="/thickFoldingFilters" element={<ThickFoldingFilters />} />
          <Route path="/carbonFilters" element={<CarbonFilters />} />
          <Route path="/filterElement" element={<FilterElement />} />
          <Route path="/filterBag" element={<FilterBag />} />
          <Route path="/airCompressor" element={<AirCompressor />} />
          <Route path="/electronicMaterials" element={<ElectronicMaterials />} />
          <Route path="/valveFittings" element={<ValveFitting />} />
          <Route path="/servicesReclaimScraps" element={<HandleReclaimScraps />} />
          <Route path="/electronicSparesRepair" element={<ElectronicSparesRepair />} />
          <Route path="/consultation" element={<Consultation />} />
      </Routes>
      <ScrollToTopBtn />
      <ContactBtn/>
      <Footer />
    </Router>
  </>
  );
};
export default App;
