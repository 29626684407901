import '../App.js'
import { Button } from 'react-bootstrap';

function Product() {
  const productList=[
    {url:'./chillerUnits', buttonLabel:'Chiller Units'},
    {url:'./dryPumpUnits', buttonLabel:'Dry Pumps'},
    {url:'./filterUnits', buttonLabel:'Filtration Solutions'},
    {url:'./electronicMaterials', buttonLabel:'Electronic Materials List'}
  ]  
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-6'>
                <h3>List of products we offer</h3>
                <br/>
                <ul className="list-group list-group-numbered">
                    {productList.map((item, index) => (
                        <Button 
                          className='text-start mb-1' 
                          key={index} 
                          href={item.url}
                        >
                          {item.buttonLabel}
                        </Button>
                    ))} 
                </ul>
                <br/>
                <br/><br/>
            </div>
        </div>
    </div>
    </>
  )
}

export default Product