import '../../../App.js'
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import './filters.css';
import FilterCard from '../../../components/filtercard.js';

function FilterElement() {
  const navigate = useNavigate();
  const handleBackClick = () => {
        navigate('/filterUnits'); 
  };
  const FilterList = [
    {
      title:'Planar Sintered Filter Element',
      src:'/images/filters/PlanarSinteredFilterElement1.png',
      desc:'',
      features:[],
      uses:[],
      material:'Carbon',
      innerDiameter:'28mm/30mm',
      outerDiameter:["65mm/70mm"],
      length:'10/20 inches',
      aperture:'5/10µm',
      minTemp:'75°C',
      maxPressure:["0.3Mpa at 20°C"],
    },
    {
      title:'Metal Folding Filter Element',
      src:'/images/filters/MetalFoldingFilterElement2.png',
      desc:'Made of imported stainless steel sintering, it is a multi-hole deep-type filter material with trapezoidal pore size, from coarse to fine. With absolute features of high filtration and dirt holding capacity, high open porosity, soft impurities. It has good blocking effect and can be folded to increase the filtering area. Increase lifeand flow.',
      features:[],
      uses:["PCB Industry","Electroplating Industry","Beverage Industry","RO Pre-filter","Chemical Process","General Water Treatmet Filtration"],
      material:'Activated Carbon Cloth',
      innerDiameter:'28mm',
      outerDiameter:["65mm"],
      length:'10/20 inches',
      aperture:'10µm',
      endCap:[""],
      minTemp:'65°C',
      maxPressure:["0.4Mpa at 20°C"],
    },
    {
      title:'Powder Sintered Filter',
      src:'/images/filters/PowderSinteredFilter3.png',
      desc:'Stainless steel powder is pressed into a mold and then sintered at high temperature. It has high mechanical strength, good high temperature resistance, good corrosion resistance, and pore size even distribution and high filtering efficiency.',
      features:[],
      uses:["PCB Industry","Electroplating Industry","Chemical Process","General Water Treatmet Filtration","RO Pre-filter"],
      material:["Activated Carbon Fiber"],
      innerDiameter:["28mm"],
      outerDiameter:["68mm"],
      length:'10/20 inches',
      aperture:["10µm"],
      endCap:[""],
      minTemp:["65°C"],
      maxPressure:["0.4Mpa at 20°C"],
    },
    {
        title:'10-inch Standard Hollow Fiber Filter',
        src:'/images/filters/10InchStandardHollowFiberFilter4.png',
        desc:'',
        features:["High throughput","Long Life"],
        uses:["Water Industry","Sterilisation Filtration","Oil Filtration"],
        material:["Polyethersulfone (PES)"],
        filterHousing:'PP/PU',
        innerDiameter:[],
        outerDiameter:["68mm"],
        length:'10 inches',
        accuracy:'0.2µm',
        aperture:[],
        endCap:["Double Opening","2-222 horizontal end","2-222 rocket end"],
        filterType:["Outside to Inside"],
        membraneArea:'1.8m²',
        lifeSpan:'86, 700L (75% pressure difference 0.5kg/cm²',
        flux:'46L/min (0.5kg/cm²)',
        operatingTemp:'10 to 65°C',
        operatingPressure:'1 to 5kg/cm²',
        oRing:'Buna-X/EPDM/Viton/Silicone',
        model:'ACORN, HF, PES, 0.2, 80 (N)',
        minTemp:[],
        maxPressure:[],
    },
  ]
  const tableFilter=[
    {
      title:'',
      material:["SUS304 / SUS316L / Ti / Ni / Cu / Other alloy"],
      length:["5 to 40 inches","Special Size Specifications"],
      aperture:["0.5 to 200µm"],
      endCap:["Double Open Ends","2-222/2-226","Various Lock Teeth, etc"],
      maxTemp:'65°C',
      operatingPressure:'0.5 to 1.5Mpa',
      maxPressure:'0.4Mpa at 20°C',
    },
    {
      title:'10-inch Standard Hollow Fiber Filter',
      material:["Polyethersulfone (PES)"],
      filterHousing:'PP/PU',
      innerDiameter:[],
      outerDiameter:["68mm"],
      length:'10 inches',
      accuracy:'0.2µm',
      aperture:[],
      endCap:["Double Opening","2-222 horizontal end","2-222 rocket end"],
      filterType:["Outside to Inside"],
      membraneArea:'1.8m²',
      lifeSpan:'86, 700L (75% pressure difference 0.5kg/cm²',
      flux:'46L/min (0.5kg/cm²)',
      operatingTemp:'10 to 65°C',
      operatingPressure:'1 to 5kg/cm²',
      oRing:'Buna-X/EPDM/Viton/Silicone',
      model:'ACORN, HF, PES, 0.2, 80 (N)',
    },
  ]
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <Button onClick={handleBackClick} className="btn btn-primary back-button mb-3">
                  <i className="fas fa-arrow-left"></i> Back
                </Button>
                <h3>Filter Element</h3>
                <br/>
                <button 
                    className="btn btn-primary" 
                    onClick={() => scrollToSection('section1')}
                >
                    View specifications below
                </button>
                <br/>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-4">
        {FilterList.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <FilterCard
              title={item.title}
              src={item.src}
              desc={item.desc}
              features={item.features}
              uses={item.uses}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="container chart-container py-5">
      <div className="row mt-5">
          <div className="col-12">
            <h4 id='section1'>Product Specifications</h4>
            <div className='table-responsive'>
                <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">Filter</th>
                    <th scope="col">Material</th>
                    <th scope="col">Filter Housing</th>
                    <th scope="col">Outer Diameter</th>
                    <th scope="col">Length</th>
                    <th scope="col">Aperture</th>
                    <th scope="col">Accuracy</th>
                    <th scope="col">End Cap</th>
                    <th scope="col">Filter Type</th>
                    <th scope="col">Membrane Area</th>
                    <th scope="col">Life Span</th>
                    <th scope="col">Flux</th>
                    <th scope="col">Maximum Operating Temperature</th>
                    <th scope="col">Maximum Operating Pressure</th>
                    <th scope="col">Operating Temperature</th>
                    <th scope="col">Operating Pressure</th>
                    <th scope="col">O-ring</th>
                    <th scope="col">Model</th>
                    </tr>
                </thead>
                <tbody>
                    {tableFilter.map((product, index) => (
                    <tr key={index}>
                        <td>{product.title}</td>
                        <td>
                        {Array.isArray(product.material) ? (
                            product.material.map((material, i) => (
                            <span key={i}>{material}<br /></span>
                            ))
                        ) : (
                            product.material
                        )}
                        </td>
                        <td>{product.filterHousing}</td>
                        <td>
                        {Array.isArray(product.outerDiameter) ? (
                            product.outerDiameter.map((diameter, i) => (
                            <span key={i}>{diameter}<br /></span>
                            ))
                        ) : (
                            product.outerDiameter
                        )}
                        </td>
                        <td>{product.length}</td>
                        <td>{product.aperture}</td>
                        <td>{product.accuracy}</td>
                        <td>
                        {Array.isArray(product.endCap) ? (
                            product.endCap.map((endCap, i) => (
                            <span key={i}>{endCap}<br /></span>
                            ))
                        ) : (
                            product.endCap
                        )}
                        </td>
                        <td>{product.filterType}</td>
                        <td>{product.membraneArea}</td>
                        <td>{product.lifeSpan}</td>
                        <td>{product.flux}</td>
                        <td>{product.maxTemp}</td>
                        <td>{product.maxPressure}</td>
                        <td>{product.operatingTemp}</td>
                        <td>{product.operatingPressure}</td>
                        <td>{product.oRing}</td>
                        <td>{product.model}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>

    <br/><br/>
    </>
  )
}

export default FilterElement