import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './filtercard.css';
import TablePopup from './tablepopup.js'

const FilterCard = ({data, title, src, desc, features, uses, characteristics }) => {
  return (
    <div className="card h-100 shadow-sm custom-card">
      {src && src.length > 0 && (
          <div>
            <img src={src} className="card-img-top mx-auto d-block" alt={title} style={{ objectFit: 'contain', height: '300px', width: '100%' }} />
          </div>
      )}
      {/* <img src={src} className="card-img-top mx-auto d-block" alt={title} style={{ objectFit: 'contain', height: '300px', width: '100%' }} /> */}
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{desc}</p>
        {features && features.length > 0 && (
          <div>
            <h6>Features:</h6>
            <ul className='custom-list'>
              {features.map((feature, index) => (
                // <li key={index}>{feature}</li>
                <li key={index}><i className="fas fa-check-circle me-2"></i>{feature}</li>
              ))}
            </ul>
          </div>
        )}
        {uses && uses.length > 0 && (
          <div>
            <h6>Application Areas</h6>
            <ul className='custom-list'>
              {uses.map((uses, index) => (
                // <li key={index}>{uses}</li>
                <li key={index}><i className="fas fa-arrow-right me-2"></i>{uses}</li>
              ))}
            </ul>
          </div>
        )}
        {characteristics && characteristics.length > 0 && (
          <div>
            <h6>Characteristics</h6>
            <ul className='custom-list'>
              {characteristics.map((characteristics, index) => (
                <li key={index}><i className="fas fa-arrow-right me-2"></i>{characteristics}</li>
              ))}
            </ul>
          </div>
        )}
        {/* {data.map((item,index)=>(
          <TablePopup 
            key={index}
            buttonLabel='Learn More'
            popupTitle={item[index].popupTitle}
            data={item.data}
          />
        ))} */}
        {data && data.length > 0 && data.map((item, index) => (
          <TablePopup 
            key={index}
            buttonLabel="Learn More"
            popupTitle={item.popupTitle}
            data={data}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterCard;
