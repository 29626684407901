import '../../App.js'
import FilterCard from '../../components/filtercard.js'

function ElectronicMaterials() {
    const materialList=[
        {
            title:'Silicon Wafers (Blank / Prime) and SiC substrates',
            src:'/images/electronicMaterials/SiWafer1.png',
            characteristics:["Si Wafers (B-dopant, P or N type","Available in 2/4/6/8/12 inches","Customised to different requirements such as high resistivity, single/double side polished, engraving on wafers and etc","Starting MOQ from as low as 500pcs","Disposal of scrapped wafers available with Certificate of Destruction to protect customer’s proprietary information"],
            uses:["Semiconductor wafer foundries","Photonics and research purposes"],
        },
        {
            title:'Sputter Target (Au, Ag, Pt, Pd, Alloy)',
            src:'/images/electronicMaterials/SputterTarget1.png',
            characteristics:["High quality target without pinhole, burr or chipping, and it is controllable crystalline structure","Highly accurate bonding process by ultrasonic flaw detection","Collect/re-process of used targets available","Available to reclaim/recover precious metals and cleaning of shields"],
            uses:["Solar Panels","Display for LCD","Optical Media (CD, DVD, BD)","Crystal Oscillator","Electrode or Reflective Film for LED"],
        },
        {
            title:'Au, Ag, Pt, Pd & other alloys (Pellet)',
            src:'/images/electronicMaterials/VaporDepoPellet1.png',
            characteristics:["Available to collect and reprocess the used material deposition","Available to reclaim/recover precious metals and cleaning of crucibles/boats and shield parts",'Available in sizes of 1/4" and 1/8',"Available in purity levels of 99.99%, 99.995% or 99.999%"],
            uses:['For PVD/CVD/E-beam evaporator']
        },
        {
            title:'Au, Ag, Pt, Pd & other alloys (Shot)',
            src:'/images/electronicMaterials/VaporDepoShot1.png',
            characteristics:["Available to collect and reprocess the used material deposition","Available to reclaim/recover precious metals and cleaning of crucibles/boats and shield parts",'Available in sizes of 1/4" and 1/8',"Available in purity levels of 99.99%, 99.995% or 99.999%"],
            uses:['For PVD/CVD/E-beam evaporator']
        },
        {
            title:'Etching Ring (Silicon)',
            src:'/images/electronicMaterials/EtchingRing1.png',
            characteristics:['Available in 6", 8" and 12"']
        },
        {
            title:'Ceramics - Alumina Oxide',
            src:'/images/electronicMaterials/AluminaOxide1.png',
            characteristics:['Purity 99.3%~99.7%']
        },
        {
            title:'Silicon Part',
            src:'/images/electronicMaterials/SiliconPart2.png',
            characteristics:['Withstand up to 200°C']
        },
        {
            title:'Quartz Ring',
            src:'/images/electronicMaterials/QuartzRing3.png',
        },
        {
            title:'Graphite & Viton Part',
            src:'/images/electronicMaterials/GraphiteVitonPart4.png',
        },
        {
            title:'Al / SUS (Electrode Plate)',
            src:'/images/electronicMaterials/ElectrodePlate1.png',
        },
        {
            title:'Al / SUS (Showerhead)',
            src:'/images/electronicMaterials/Showerhead2.png',
        },
        {
            title:'Al / SUS (Dome)',
            src:'/images/electronicMaterials/Dome3.png',
        },
        {
            title:'Al / SUS (Top Plate)',
            src:'/images/electronicMaterials/TopPlate4.png',
        },
        {
            title:'Al / SUS (Heater Plate)',
            src:'/images/electronicMaterials/HeaterPlate5.png',
        },
    ]
  return (
    <>
    <div className='container-md text-start mt-5'>
        <div className='row'>
            <div className='col-12 col-md-9'>
                <h3>Electronic Materials</h3>
                <br/>
                <h5 className='fw-normal'>We offer a wide range of Electronic Materials solutions for semiconductor applications, ranging from sputtering targets, evaporator pellets, Si wafers to Silicon/Alumina parts.</h5>
                <br/>
            </div>
        </div>
    </div>
    <div className="container chart-container py-5">
        <div className="row mt-4">
            {materialList.map((item, index) => (
            <div key={index} className="col-md-6 col-lg-3 text-start mb-4">
                <FilterCard
                title={item.title}
                src={item.src}
                desc={item.desc}
                features={item.features}
                uses={item.uses}
                characteristics={item.characteristics}
                />
            </div>
            ))}
        </div>
    </div>
    <br/><br/>
    </>
  )
}

export default ElectronicMaterials